import { Component, OnInit, ViewChild, NgZone, HostListener, AfterViewInit, AfterViewChecked, AfterContentInit, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationStart } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';
import { map, first } from "rxjs/operators";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ifStmt, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EmailValidator } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { listenerCount } from 'process';
import { DatePipe, HashLocationStrategy } from '@angular/common';
import { bindCallback } from 'rxjs';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { NgxCaptureService } from 'ngx-capture';
import { DomSanitizer } from '@angular/platform-browser';
import { jsPDF } from "jspdf";
import { catchError, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { jsonpFactory } from '@angular/http/src/http_module';
import { PDFDocument, restoreDashPattern } from 'pdf-lib';
import { ActivatedRoute } from '@angular/router'; // <-- do not forget to import
import { NavbarService } from '../navbarservice.service';
import { PlotsService } from '../plots.service';
import { NewsletterService } from '../newsletter.service';
import { Meta } from '@angular/platform-browser';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { last } from 'rxjs-compat/operator/last';


// import * as GoogleAuth from 'google-auth-library';
// import * as google from 'googleapis';




@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css'],
})
export class TestingComponent implements OnInit, AfterViewInit {

  closeResult = '';
  yourName;
  yourEmail;
  friendsName;
  friendsEmail;
  tGain;
  tLaunch;
  nsdqVal;
  stampForGraph: any;
  backtestingText;
  private fragment: string;
  currMonth: Date = new Date();
  DynamUpd;

  isBrochure: Boolean = false;

  mobile: Boolean = false;
  screenSize: Boolean = false;

  constructor(private modalService: NgbModal, private http: HttpClient, private config: ConfigService,
    private router: Router, private date: DatePipe, private captureService: NgxCaptureService, private _sanitizer: DomSanitizer, private ngZone: NgZone, private _actRoute: ActivatedRoute,
    public nav: NavbarService, private plots: PlotsService, private newsletter: NewsletterService, private meta: Meta, private tooltip: NgbTooltipModule, private renderer: Renderer2) {

  }

  ngOnInit(): void {
    // document.getElementById("fb-root").removeAttribute("style");
    this.username = "cosmicetoro";
    // window.history.replaceState(null, null, "");

    this.submit();

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    if (this.mobile == true) {
      this.backtestingText = "The chart above"
    } else if (this.mobile == false) {
      this.backtestingText = "The chart on the left"
    }

    this.nav.hideSet(false);


    const element = document.getElementById("chartSize");
    // console.log("Width: " + element.offsetWidth + "px");
    // console.log("Height: " + element.offsetHeight + "px");

    // document.getElementById('benchmarkPlotImg').style.width = element.offsetWidth.toString() + "px";
    // document.getElementById('executionsPlot').style.width = element.offsetWidth.toString() + "px";

    // console.log(window.innerWidth)
    // console.log(window.innerHeight)



    this.getNSDQValues();
    // this.DynamUpd = this.currMonth.getHours().toString() +":"+ this.currMonth.getMinutes().toString() +":"+ this.currMonth.getSeconds().toString() + " " + this.currMonth.getDate().toString()+"/"+this.currMonth.getMonth().toString();
    // console.log(this.currMonth.getMonth());
    let hours;
    let minutes;
    let seconds;

    if(this.currMonth.getHours() < 10){
      hours = "0" + this.currMonth.getHours().toString();
    }else{
      hours = this.currMonth.getHours().toString();
    }

    if(this.currMonth.getMinutes() < 10){
      minutes = "0" + this.currMonth.getMinutes().toString();
    }else{
      minutes = this.currMonth.getMinutes().toString();
    }

    if(this.currMonth.getSeconds() < 10){
      seconds = "0" + this.currMonth.getSeconds().toString();
    }else{
      seconds = this.currMonth.getSeconds().toString();
    }

    this.DynamUpd = hours + ":" + minutes + ":" + seconds + " " + this.currMonth.toDateString();

    // this.newsLetCapt();
    this.meta.addTags([
      { name: 'title', content: "Backseat Trader's algorithm on the eToro trading platform" },
      { name: 'description', content: "An overview as to how the Backseat Trader algorithm was developed to run on the eToro platform, trading the Nasdaq-100 top 20 constituent stocks, updated on a monthly cycle, adapting to market condition changes" },
      { property: 'og:title', content: "Backseat Trader's algorithm on the eToro trading platform" },
      { property: 'og:description', content: "An overview as to how the Backseat Trader algorithm was developed to run on the eToro platform, trading the Nasdaq-100 top 20 constituent stocks, updated on a monthly cycle, adapting to market condition changes" },
      { property: 'og:image', content: "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-1/312504689_123467517198492_1382274970462418694_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=d36de4&_nc_ohc=r1PMaUUOe34AX9J_tzp&_nc_ht=scontent-iad3-1.xx&oh=00_AfB4IGibtjL-pBVq0vDQQ9WksUAPnFb41b_5hkxUJ0TmpQ&oe=645A6FBE" },
      { property: 'og:url', content: "https://backseat-trader.com/" },
      { property: 'twitter:title', content: "Backseat Trader's algorithm on the eToro trading platform" },
      { property: 'twitter:description', content: "An overview as to how the Backseat Trader algorithm was developed to run on the eToro platform, trading the Nasdaq-100 top 20 constituent stocks, updated on a monthly cycle, adapting to market condition changes" },
      { property: 'twitter:image', content: "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-1/312504689_123467517198492_1382274970462418694_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=d36de4&_nc_ohc=r1PMaUUOe34AX9J_tzp&_nc_ht=scontent-iad3-1.xx&oh=00_AfB4IGibtjL-pBVq0vDQQ9WksUAPnFb41b_5hkxUJ0TmpQ&oe=645A6FBE" },
      { property: 'twitter:url', content: "https://backseat-trader.com/" },



    ]);

    // let widthBenchStr = document.getElementById('benchmarkPlotImg').style.width;
    // widthBenchStr = widthBenchStr.replace("px", "");
    // let widthBench = Number(widthBenchStr)
    // console.log(widthBench)

    // let widthExStr = document.getElementById('executionsPlot').style.width;
    // widthExStr = widthExStr.replace("px", "");
    // let widthEx = Number(widthExStr);

    if (window.innerWidth < 1920) {
      this.screenSize = true;
      // widthBench = widthBench - 80%;
      // widthBench = widthBench - (widthBench * (20 / 100));
      // widthEx = widthEx - (widthEx * (20 / 100))
      // document.getElementById('benchmarkPlotImg').style.width = widthBench.toString() + "px";
      // document.getElementById('executionsPlot').style.width = widthEx.toString() + "px";
      // console.log(widthBench, "widthBench")
    }


    if(sessionStorage.getItem('windowPosSubSEO')){
      sessionStorage.removeItem('windowPosSubSEO');
    }
    
  }

  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  @ViewChild('nsdq', { static: true }) screenElementNSDQ: ElementRef;
  @ViewChild('exe', { static: true }) screenElementEXE: ElementRef;
  private scrolling = false;
  private scrollingTimer: any;

  ngAfterViewInit(): void {
    // this.newsLetCapt();
    this._actRoute.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      // console.log(element)
      // console.log(f)
      if (element) element.scrollIntoView();
    })

    // this.checkScroll();
    let winPos = sessionStorage.getItem("windowPos");
    if(winPos){
      // console.log("found!")
      let numWinPos = Number(winPos);
      // window.location.replace('http://localhost:4200/');
      if(numWinPos > 0){
        // console.log("scrolling!", numWinPos)
        window.scrollTo(0, numWinPos);
        // console.log(window.scrollY)
        if(window.scrollY != numWinPos){
          // console.log("scrolling again!")
          window.scrollTo(0, numWinPos)
        }
      }
    }
  }

  // @HostListener('document:scroll', ['$event'])
  // onScroll(event): void {
  //   if(!this.scrolling){
  //     this.scrollingTimer = setTimeout(() => {
  //       this.scrolling = true;
  //     }, 500);
  //   }
  //   // this.checkScroll();
  //   this.handleScroll(this.screenElement.nativeElement);
  //   this.handleScroll(this.screenElementEXE.nativeElement);
  //   this.handleScroll(this.screenElementNSDQ.nativeElement);
  // }

  // @HostListener('document:keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent): void {
    // this.scrolling = false;
    // console.log("keyed!")
    // console.log(window.scrollY)
    // console.log(this.scrolling)
  // }

  // private handleScroll(element: HTMLElement): void {
  //   console.log(this.scrolling)
  //   if (this.scrolling && !this.mobile && this.router.url != "/#home" && this.router.url != "/#about" && this.router.url != "/#brochure") {
  //     const rect = element.getBoundingClientRect();
  //     const marginAdjustment = 0.07;
  //     const middleOfScreen = window.innerHeight / 2;
  //     const middleOfElement = rect.height / 2;

  //     if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
  //       const margin = middleOfScreen - middleOfElement + marginAdjustment * window.innerHeight;
  //       element.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'center',
  //       });
  //     }
  //   }
  // }


  onScrollTo(location: string) {
    setTimeout(() => { this.router.navigate([], { fragment: location }); }, 500);
  }

  async loginCheck() {
    let username = await localStorage.getItem('username');
    let ftu = await localStorage.getItem('FTU');
    // console.log(ftu)

    if (username) {
      this.router.navigateByUrl('/user');

    } else {
      if (!ftu) {
        this.router.navigateByUrl('/register');
        localStorage.setItem('FTU', '1');
      } else if (ftu) {
        this.router.navigateByUrl('/login');
      } 
    }
      this.capturePos();
  }

  nsdqDimensions: any;
  plotDimensions: any;
  async setDimension() {
    const dimensionsMain = this.screen.nativeElement.getBoundingClientRect();
    const dimensiosNsdq = this.nsdqScreen.nativeElement.getBoundingClientRect();

    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c5f0b7c265b2a0e7819',
      'content-type': 'application/json'
    }

    let mainObj = {
      "widthMain": dimensionsMain.width,
      "heightMain": dimensionsMain.height
    }

    let nsdqObj = {
      "widthNSDQ": dimensiosNsdq.width,
      "heightNSDQ": dimensiosNsdq.height
    }

    await this.http.post<any>('https://cosmicmedia-a0ce.restdb.io/rest/graphdimension', { 'theDimension': mainObj }, { headers: headers }).subscribe(data => {
      // 'https://rss.app/api/v1/feed/VKQizmR7fT4Q9q9B'
      // console.log(data);
    })

    await this.http.post<any>('https://cosmicmedia-a0ce.restdb.io/rest/graphdimension', { 'theDimension': nsdqObj }, { headers: headers }).subscribe(data => {
      // 'https://rss.app/api/v1/feed/VKQizmR7fT4Q9q9B'
      // console.log(data);
    })
  }

  async getDimension() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/graphdimension', { headers }).subscribe(data => {
      // 'https://rss.app/api/v1/feed/VKQizmR7fT4Q9q9B'
      // console.log(data);
      this.plotDimensions = data[0].theDimension;
      this.nsdqDimensions = data[1].theDimension;

      // console.log(this.plotDimensions);
      // console.log(this.nsdqDimensions)
    })
  }

  async getTheDate(date) {
    var recentDate = date[date.length - 1];
    // console.log(recentDate);

    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c5f0b7c265b2a0e7819',
      'content-type': 'application/json'
    }

    const body = {
      'theDate': recentDate,
    }
    await this.http.post<any>('https://cosmicmedia-a0ce.restdb.io/rest/thedate', { 'theDate': recentDate }, { headers: headers }).subscribe(data => {
      // 'https://rss.app/api/v1/feed/VKQizmR7fT4Q9q9B'
      // console.log(data);
    })

  }

  theBrochurePDF = "https://api.backseat-trader.com/rest/brochure/";
  async theBrochure() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://api.backseat-trader.com/rest/brochure', { headers }).subscribe(data => {
      // 'https://rss.app/api/v1/feed/VKQizmR7fT4Q9q9B'
      // console.log(data);
      data.forEach(pdf => {
        this.theBrochurePDF += pdf.theBrochure[0];
        // console.log(this.theBrochurePDF)     
        // this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
        //          + img.img[0]);
      })
    })

    // this.downloadBase64Data(this.theBrochureData, "Brochure.pdf");
    // this.DataURIToBlob(this.theBrochureData);
  }

  imagePath: any;
  chartIn = false;
  plotVal: String = "https://api.backseat-trader.com/media/";
  nsdqDown = "https://cosmicmedia-a0ce.restdb.io/media/";
  async nsdqPlotDownload() {
    let thePlotVal = "https://api.backseat-trader.com/media/"
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://api.backseat-trader.com/rest/nsdqchart', { headers }).subscribe(data => {
      // 'https://rss.app/api/v1/feed/VKQizmR7fT4Q9q9B'
      // console.log(data);
      data.forEach(img => {
        // console.log(img.img[0]);
        this.plotVal += img.img[0];
        this.nsdqDown += img.img[0];
        thePlotVal += img.img[0];
        this.ngZone.run(() => {
          this.chartIn = true;
        });
        //   // this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
        //   //          + img.img[0]);
      })
      // console.log(thePlotVal)
      // return thePlotVal;
      // console.log(data[0].img[0]);
      // var val = (<HTMLInputElement>document.getElementById('nsdqIMG'));
      // var img = new Image();
      // img.src = "https://www.google.com/images/srpr/logo4w.png"
      // val.appendChild(img);
    })

    // console.log(thePlotVal)
  }

  getNSDQValues() {
    this.plots.setNSDQValues().toPromise().then(data => {
      // console.log(data);
    })
  }

  // tradeExVal = "https://api.backseat-trader.com/media/";
  async tradeExDownload() {
    // console.log(this.plotVal)
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://api.backseat-trader.com/rest/trade-executions-plot', { headers }).subscribe(data => {
      // 'https://rss.app/api/v1/feed/VKQizmR7fT4Q9q9B'
      // console.log(data);
      data.forEach(img => {
        // console.log(img.tradePlot[0]);

        // this.tradeExVal += img.tradePlot[0];
        // this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
        //          + img.img[0]);
      })
    })
  }
  //////////////////////////////////////////////////////////////////////////
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //post goes here
      this.checkEmail(this.friendsEmail);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async checkEmail(email) {
    if (email == null || email == "" || email.indexOf('@') == -1) {
      alert("Invalid, you must use a valid email address.");
    } else {
      // console.log("success");
      this.http.post<any>(this.config.url + 'user/refer', { yourNameRF: this.yourName, yourEmailRF: this.yourEmail, friendsNameRF: this.friendsName, friendsEmailRF: this.friendsEmail }).pipe(first()).subscribe(data => {
        // console.log('register sucess');
        alert("Success.");
      }, err => {
        alert("Error.");
        // console.log('error in register');
        // console.log(err);
      })
    }
  }
  ///////////////////////////////////////////////////////////////////////////
  /*^^^^^^^^^^^^^^^^^^^^^^^^ REFER A FRIEND ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  test: Date = new Date();

  subscribeEmail: string;

  getPath() {
    return this.router.url;
  }
  ////////////////////////////////////////////////////////////////////////////
  submitFoot() {
    this.checkEmailFoot(this.subscribeEmail);
  }

  async checkEmailFoot(email) {
    if (email == null || email == "" || email.indexOf('@') == -1) {
      alert("Invalid, you must use a valid email address.");
    } else {
      // console.log("success");
      // console.log(email);

      var url = this.config.url;

      await this.http.post<any>(url + 'user/news', { email: email }).pipe(first()).subscribe(data => {
        // console.log('register sucess');
        alert("Success.");
      }, err => {
        // console.log('error in register');
        alert("Error.");
      })

    }
  }
  //////////////////////////////////////////////////////////////////////////////
  /*^^^^^^^^^^^^^^^^^^^^^^^^^^^^NEWSLETTER SUBSCRIBE^^^^^^^^^^^^^^^^^^^^^^^^^^*/
  username;
  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];

  updateRecent: any;

  public options: any = {

    // chart: {
    //   type: 'column'
    // },
    // title: {
    //   text: 'Our Monthly Gains to Date'
    // },

    // xAxis: {
    //   categories: ['Green', 'Pink']
    // },

    // series: [{
    //   data: [{
    //     name: 'Point 1',
    //     color: '#00FF00',
    //     y: 1
    //   }, {
    //     name: 'Point 2',
    //     color: '#FF00FF',
    //     y: 5
    //   }]
    // }],



  }

  last = 0;

  async getMonthlyGains() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      // console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });

      for(let i = 0; i<= this.gainsMonthly.length - 1; i++){
        this.last += this.gainsMonthly[i];
      }

      let z;
      // this.last = this.last - 5.1;

      this.last = this.last - 5.1;

      if(this.last >= 10){
        z = this.last.toFixed(1);
      }else{
        z = this.last.toFixed(2);
      }

      this.last = z;
  

      // this.last = this.gainsMonthly[this.gainsMonthly.length - 1]
      this.stampForGraph = this.timestampMonthly[this.timestampMonthly.length - 1];
      this.updateRecent = this.timestampMonthly[this.timestampMonthly.length - 1]
      // this.getTheDate(this.timestampMonthly);
      // console.log(this.timestampMonthly);
      // console.log(this.stampForGraph);
      this.populateGraphWithDynamicValues();
      // this.monthlyGain = [];
      // this.parsedMonthly = [];
      // this.gainsMonthly = [];
      // this.timestampMonthly = [];
    })
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth() + 1), d.getFullYear()].join('/')
  }


  gPos = false;
  lPos = false;
  perfLaunch;
  populateGraphWithDynamicValues() {
    var dateArr = [];
    var date;



    for (var i = 0; i <= this.timestampMonthly.length; i++) {
      // console.log(true)
        
        // if(i % 6 == 0){
        //   console.log(true)
          // this.timestampMonthly[i] == "";
          // date = new Date(this.timestampMonthly[i]);
          date = new Date(this.timestampMonthly[i]);
          dateArr.push(this.convertDate(date))
        // }else{
        //   // dateArr.push(undefined)

        // }

      
    }

    // let start = 0;
    // let end = dateArr.length - 3;
    // let mid = Math.round((start+end)/2);

    // let distance = end - mid;

    // console.log(end)
    // console.log(mid)
    // console.log(distance)
    // console.log(dateArr.length)
    // console.log(dateArr)

    let firstDate = dateArr.shift();

    let first = this.gainsMonthly.shift();
    let initialise = this.gainsMonthly[0];

    //console.log(this.gainsMonthly);
    let plot = [];
    initialise = (initialise * 12 / 1);
    // console.log(initialise);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    this.gainsMonthly.splice(0,17);
    // buff.splice(0,17);
    dateArr.splice(0,17);
    dateArr.pop();

    let monthGains = this.gainsMonthly;

    for (var i = 0; i <= this.gainsMonthly.length - 1; i++) {
      if (i == 1) {
        buff.push(this.gainsMonthly[i] + this.gainsMonthly[i - 1]);
      } else {
        buff.push(this.gainsMonthly[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = this.gainsMonthly[0];

    console.log(buff);

    /**NEED TO DISCUSS THE REMOVAL OF THIS*/
    // let y = 1;

    // for (var i = 0; i <= buff.length - 1; i++) {
    //   buff[i] = (buff[i] * 12 / y);
    //   y++;
    //   buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    // }
    	/**NEED TO DISCUSS THE REMOVAL OF THIS*/

    console.log(buff);

    this.tGain = buff[7];
    // console.log(this.tGain)
    let x = this.tGain.toPrecision(3);
    // console.log(x)
    this.tGain = x;
    this.perfLaunch = buff[buff.length - 1];
    // let z = this.perfLaunch.toPrecision(2);
    // this.perfLaunch = z;

    // this.perfLaunch = this.perfLaunch - 5.1;

    if(this.perfLaunch >= 10){
      this.perfLaunch = this.perfLaunch.toFixed(1);
    }else{
      this.perfLaunch = this.perfLaunch.toFixed(2);
    }

    localStorage.setItem("agrGAIN", this.perfLaunch.toString())


    if (this.tGain < 0) {
      document.getElementById("tGain").className = "legendRed";
      this.gPos = false;
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }


    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    // console.log(sum, "sum");

    let avg = sum / buff.length - 1;

    // console.log(avg, "avg");

    let agr = sum * 12 / 8;
    // console.log(agr);

    // for(var index = 0; index <= this.gainsMonthly.length; index++){
    //   this.gainsMonthly[index] = this.gainsMonthly[index] * 100;
    // }



    let plotBandText;
    let plotBandPos;
    let plotBandY;
    let plotBandTextCol;
    let plotBandAlign;
    let plotBandLabelText;
    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;
    let steps;


    // this.gainsMonthly.splice(0,17);
    // buff.splice(0,17);
    // dateArr.splice(0,17);
    // dateArr.pop();

    let start = 0;
    let end = dateArr.length - 1;
    let mid = Math.round((start+end)/2);

    let distance = end - mid;

    if (this.mobile == true) {
      plotBandText = "55%";
      plotBandPos = 'bottom';
      // plotBandY = 210;
      // plotBandTextCol = '#44AAD5';
      // plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      // splineWidth = '3';
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      // plotBandText = "100%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of 15 - 25%';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '3';
      // arrowLengthDyn = 15;
      // arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
      steps = distance + 1;
    } else {
      plotBandText = "100%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of 15 - 25%';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '3';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
      steps = 3;
    }



    // console.log(this.gainsMonthly)

    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        alignTicks: false,
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            // pointDistance = Math.sqrt(
            //   (point0.plotX - point1.plotX) * (point0.plotX - point1.plotX) +
            //   (point0.plotY - point1.plotY) * (point0.plotY - point1.plotY)
            // );

            // angle = 90 + Math.atan2((point0.plotY - point1.plotY), (point0.plotX - point1.plotX)) * 180 / Math.PI;

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            // console.log(angle);

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            // console.log(calculatedPath)
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#1F85DE',
                // 'stroke-width': 2,

              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }
          }
        },

        type: 'column',

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: dateOrNot, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true,

      }, { // Secondary yAxis

        title: {
          text: 'Monthly Return - (Target > 1.25%)',
          style: {
            color: Highcharts.getOptions().colors[2],
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        tickPositioner: function (min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);

          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max + step)); //hidden - added for top padding

          return ticks;
        },
        // max: 50,

        gridLineWidth: gridOrNot
      }, {
        gridLineWidth: gridOrNot,
        // linkedTo:1,
        max: 55,
        min: -60,
        tickInterval: 15,
        plotBands: [{
          from: 15,
          to: 25,
          color: 'rgba(68, 170, 213, 0.2)',
          label: {
            // text: plotBandLabelText,
            verticalAlign: plotBandPos,
            align: plotBandAlign,
            y: plotBandY,
            style: {
              fontSize: plotBandText,
              fontWeight: 'bold',
              color: plotBandTextCol,
            }
          }
        }],
        title: {
          text: 'Annualised Gain Rate - (Target 15% - 25%)',
          style: {
            color: "#44AAD5"
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: "#44AAD5"
          }
        },
        opposite: true,
      }],
              legend: {
            labelFormat: '<span style="color:{color}">{name}</span>'
        },

      title: {
        text: '',
      },
      plotOptions: {
        column: {
          maxPointWidth: 100,
          zones: [{
            value: 0, // Values up to 10 (not including) ...
            color: 'red' // ... have the color blue.
          }, {
            color: '#6AAC0E' // Values from 10 (including) and up have the color red
          }]
        },
        spline: {
          // shared options for all line series
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              lineWidth: splineWidth,
            }
          }
        }
      },
      series: [{
        name: 'Monthly Gains',
        data: this.gainsMonthly,
        yAxis: 1,
        color: Highcharts.getOptions().colors[2]
      }, {
        yAxis: 2,
        type: 'spline',
        dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Gain +/-',
        color: '#1F85DE',
        data: buff,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,

          //   symbol: 'triangle', 
          // fillColor: 'rgba(126,86,100,.9)', /* match to the color of your column */
          // radius:25
        }
      }]

    }

    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 3;
    }
    Highcharts.chart('container', this.options);
  }

  async submit() {
    await this.getMonthlyGains();
    // await this.setDimension();
    // await this.theBrochure();
    // await this.getRawRSS();
    await this.getDimension();
    // await this.nsdqPlotDownload();
    // await this.tradeExDownload();
    await this.getNSDQ();
    await this.getBenchmark();
    await this.getExecutions();
    await this.getExecutionBanner();

  }
  @ViewChild('screen', { static: true }) screen: any;
  @ViewChild('nsdq', { static: true }) nsdqScreen: any;
  imgBase64: any = '';
  nsdqBase64: any = '';
  // nsdqPDFImg: any;
  loading: Boolean = false;
  untilNewBrochure: boolean = false;
  async capture() {

    if (this.mobile || !this.mobile) {
      window.open('https://docs.google.com/gview?url=https://cosmicmedia-a0ce.restdb.io/media/cosmic_brochure.pdf');
    }else if(this.untilNewBrochure){
      this.loading = true;
      // const dimensionsMain = this.screen.nativeElement.getBoundingClientRect();
      // console.log(dimensionsMain);
      document.getElementById("legend").classList.remove("legendShadow");
      document.getElementById("legendBench").classList.remove("legendShadow");
      await this.captureService.getImage(this.screen.nativeElement, false, {
        width: this.plotDimensions.widthMain,
        height: this.plotDimensions.heightMain,
        useCORS: true,
      })
        .pipe(
          tap((img) => {
            // console.log(img);
  
            this.imgBase64 = img;
  
            document.getElementById("legend").classList.add("legendShadow");
            document.getElementById("legendBench").classList.add("legendShadow");
          })
        )
        .subscribe();
  
      const dimensions = this.nsdqScreen.nativeElement.getBoundingClientRect();
      this.captureService
        .getImage(this.nsdqScreen.nativeElement, false, {
          width: this.nsdqDimensions.widthNSDQ,
          height: this.nsdqDimensions.heightNSDQ,
          // width: dimensions.width,
          // height: dimensions.height,
          useCORS: true,
        })
        .pipe(
          tap((img) => {
            // console.log(img);
            this.nsdqBase64 = img;
            this.save();
          })
        )
        .subscribe();
    }
  }

  DataURIToBlob(dataURI: string) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  // mySrc: any;
  // my2ndSrc: any;
  async save() {

    var pdf: any;
    if (this.mobile) {
      pdf = new jsPDF("p", "mm", [210, 420]);
    } else if (!this.mobile) {
      pdf = new jsPDF("p", "mm", "a4");
    }

    let latImg = 0;
    let latNsdq = 0;

    let widthNSDQMob = pdf.internal.pageSize.getWidth() + 550;
    let widthNSDQDesk = pdf.internal.pageSize.getWidth() + 120;
    let widthNSDQ = 0;

    let heightNSDQDesk = 144;
    let heightNSDQMob = 350;
    let heightNSDQ = 0;

    let position = 0;
    let positionDesk = 150;
    let positionMob = 155;

    let imgWidthDesk = pdf.internal.pageSize.getWidth() + 120;
    let imgHeightDesk = 144;

    let imgWidthMob = pdf.internal.pageSize.getWidth() + 550;
    let imgHeightMob = 310;
    let imgHeight = 0;
    let imgWidth = 0;

    let positionNSDQ = 0;
    let positionNSDQMob = 5;
    let positionNSDQDesk = 10;
    // let widthNSDQ = 500;

    if (this.mobile == true) {
      latImg = 20;
      latNsdq = 20;
      widthNSDQ = widthNSDQMob;
      heightNSDQ = heightNSDQMob;

      imgWidth = imgWidthMob;
      imgHeight = imgHeightMob;

      position = positionMob;
      positionNSDQ = positionNSDQMob;
    } else if (this.mobile == false) {
      if (window.innerWidth == 1680) {
        latImg = -40;
        latNsdq = -40;
      } else if (window.innerWidth == 1920) {
        latImg = -60;
        latNsdq = -60;
      } else if (window.innerWidth == 1600) {
        positionNSDQDesk = 20;
        positionDesk = 170
        latImg = -35;
        latNsdq = -35;
      } else if (window.innerWidth == 1440) {
        positionNSDQDesk = 20;
        positionDesk = 170
        latImg = -20;
        latNsdq = -20;
      } else if (window.innerWidth == 1400) {
        positionDesk = 160;
        latImg = -15;
        latNsdq = -15;
      } else if (window.innerWidth == 1366) {
        positionNSDQDesk = 20;
        positionDesk = 190
        latImg = -15
        latNsdq = -15;
      } else if (window.innerWidth == 1360) {
        positionNSDQDesk = 10;
        positionDesk = 190
        latImg = -15;
        latNsdq = -15;
      } else if (window.innerWidth == 1280) {
        latImg = -5;
        latNsdq = -5;
      } else if (window.innerWidth == 1152) {
        latImg = 5;
        latNsdq = 5;
      } else if (window.innerWidth == 1024) {
        latImg = 15;
        latNsdq = 15;
        positionDesk = 140;
        positionNSDQDesk = 0;
      } else {
        latImg = -60;
        latNsdq = -60;
      }
      widthNSDQ = widthNSDQDesk;
      heightNSDQ = heightNSDQDesk;

      imgHeight = imgHeightDesk;
      imgWidth = imgWidthDesk;

      position = positionDesk;
      positionNSDQ = positionNSDQDesk;
    }

    try {
      pdf.addImage(
        this.nsdqBase64,
        "PNG",
        latNsdq,
        position,
        widthNSDQ,
        heightNSDQ
      );
    } catch (exception) {
      this.loading = false;
    }
    try {
      pdf.addImage(
        this.imgBase64,
        "PNG",
        latImg,
        positionNSDQ,
        imgWidth,
        imgHeight
      );
    } catch (exception) {
      this.loading = false;
    }
    // console.log(this.nsdqPDFImg)
    // console.log("here")


    // pdf.text("Backseat Trader Performance", 0, 0)
    // pdf.save("cv.pdf");
    let arrayB = pdf.output('arraybuffer');

    const pdfDoc = await PDFDocument.create();

    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '631766dc0b7c265b2a0e7815'
    }

    const firstPdfBytes = await fetch('https://cosmicmedia-a0ce.restdb.io/media/636a6a705057d14f000a0747', { headers }).then(res => res.arrayBuffer());

    var bytes = new Uint8Array(firstPdfBytes);
    // console.log(bytes)
    // console.log(secondPdfBytes)

    const firstDoc = await PDFDocument.load(bytes);
    // console.log("here")
    // console.log(await firstDoc.getPageIndices())
    const firstPage = await pdfDoc.copyPages(firstDoc, firstDoc.getPageIndices());

    firstPage.forEach((page) => pdfDoc.addPage(page));

    const secondDoc = await PDFDocument.load(arrayB);
    // console.log(arrayB)

    const secondPage = await pdfDoc.copyPages(secondDoc, secondDoc.getPageIndices());
    secondPage.forEach((page) => pdfDoc.insertPage(1, page));

    pdfDoc.setTitle("Information");

    const pdfBytes = await pdfDoc.save();

    let theFile = new Blob([pdfBytes], { type: 'application/pdf' });

    var fileURL = URL.createObjectURL(theFile);

    // let formData = new FormData();

    // var theFilePost = new File([theFile], "information.pdf")

    // formData.append('myfile', theFilePost, theFilePost.name);

    // console.log(theFilePost)

    // await this.http.post<any>('https://cosmicmedia-a0ce.restdb.io/rest/main-plot-pdf', {'pdf': formData }, { headers: headers }).subscribe(data => {
    //   console.log(data);
    // })

    // if (!this.mobile) {
    //   window.open(fileURL);
    // } else if (this.mobile) {
    //   window.open('https://docs.google.com/gview?url=https://cosmicmedia-a0ce.restdb.io/media/cosmic_brochure.pdf');
    // }

    window.open('https://docs.google.com/gview?url=https://cosmicmedia-a0ce.restdb.io/media/cosmic_brochure.pdf');

    this.loading = false;
  }
  convertBase64ToFile = (base64String, fileName) => {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
  }

  downloadBase64Data = (base64String, fileName) => {
    let file = this.convertBase64ToFile(base64String, fileName);
    saveAs(file, fileName);
  }
  nPos = false;
  async getNSDQ() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/nsdqval', { headers }).subscribe(data => {
      data.forEach(number => {
        // console.log(number);
        this.nsdqVal = number.nsdq_number;
        if (this.last < 0) {
          this.nPos = false;
          document.getElementById("tNSDQ").className = "legendRed";
        } else {
          this.nPos = true;
          document.getElementById("tNSDQ").className = "legendGreen";
        }
      })
    })


  }

  etoroInf(x) {
    let a = "etoro";
    let b = "smartStock";
    let c = "advanced";
    let d = "diversified";
    let e = "bubbles";
    let f = "how";

    let mainFrag = 'about';
    let otherFrag = 'bubbles';

    if (x == 1) {
      localStorage.setItem("info", a);
      this.getInfo(a, mainFrag);
    } else if (x == 2) {
      localStorage.setItem("info", b);
      this.getInfo(b, mainFrag);
    } else if (x == 3) {
      localStorage.setItem("info", c);
      this.getInfo(c, mainFrag);
    } else if (x == 4) {
      localStorage.setItem("info", d);
      this.getInfo(d, mainFrag);
    } else if (x == 5) {
      localStorage.setItem("info", e);
      this.getInfo(e, otherFrag);
      sessionStorage.removeItem("windowPosBubbles");
    } else if (x == 6) {
      localStorage.setItem("info", f);
      this.getInfo(f, mainFrag);
    }

      this.capturePos();
    // this.router.navigateByUrl('/information/1');
  }

  getInfo(state, id) {
    // console.log("here")
    // let state = localStorage.getItem('info');
    // this.theState.push(state);

    if (state == 'etoro') {
      this.router.navigate(['/information/about-ibkr']);
      localStorage.setItem('fragment', id);
    } else if (state == 'smartStock') {
      this.router.navigate(['/information/smart-stock-curation']);
      localStorage.setItem('fragment', id);
    } else if (state == 'advanced') {
      this.router.navigate(['/information/advanced-analytics-and-intelligence']);
      localStorage.setItem('fragment', id);
    } else if (state == 'diversified') {
      this.router.navigate(['/information/diversified-approach']);
      localStorage.setItem('fragment', id);
    } else if (state == 'bubbles') {
      this.router.navigate(['/information/methodology']);
      localStorage.setItem('fragment', id);
    } else if (state == 'how') {
      this.router.navigate(['/information/how-it-works']);
      localStorage.setItem('fragment', id);
    }
  }

  NSDQ100Arr;
  NSDQ100ArrFiltered = [];
  performanceArr;
  performanceArrFiltered = [];

  nPosBench: boolean = false;
  nBenchVal;

  ePosBench: boolean = false;
  eBenchVal;

  async getBenchmark() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/benchmark-values', { headers }).subscribe(data => {
      // console.log(data)
      this.NSDQ100Arr = data[0].NSDQ100;
      this.performanceArr = data[0].Performance;

      // console.log(this.NSDQ100Arr)
      // console.log(this.performanceArr)

      this.NSDQ100ArrFiltered = this.NSDQ100Arr.split(",");
      this.performanceArrFiltered = this.performanceArr.split(",");
      
      for(let i = 0; i <= this.performanceArrFiltered.length - 1; i++){
        this.performanceArrFiltered[i] = Number(this.performanceArrFiltered[i])
      }

      for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
        this.NSDQ100ArrFiltered[i] = Number(this.NSDQ100ArrFiltered[i]);
      }

      // console.log(this.gainsMonthly)
      // console.log(this.NSDQ100ArrFiltered)

      // this.nBenchVal = this.NSDQ100ArrFiltered[this.NSDQ100ArrFiltered.length - 1];
      // this.eBenchVal = this.gainsMonthly[this.gainsMonthly.length - 2];
      let nsdqSum = 0;
      for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
       nsdqSum += this.NSDQ100ArrFiltered[i];
      }
      this.nBenchVal = nsdqSum;

      let etoroSum = 0;
      for(let i = 0; i<= this.gainsMonthly.length - 2; i++){
        // console.log(this.gainsMonthly[i])
        etoroSum += this.gainsMonthly[i]
      }

      this.eBenchVal = etoroSum;


      if(this.nBenchVal >= 10){
        this.nBenchVal = this.nBenchVal.toFixed(1);
      }else{
        this.nBenchVal = this.nBenchVal.toFixed(2);
      }

      if(this.eBenchVal >= 10){
        this.eBenchVal = this.eBenchVal.toFixed(1);
      }else{
        this.eBenchVal = this.eBenchVal.toFixed(2);
      }

      // if (this.nBenchVal < 0) {
      //   this.nPosBench = false;
      //   document.getElementById("tNSDQBench").className = "legendRed";
      // } else {
      //   this.nPosBench = true;
      //   document.getElementById("tNSDQBench").className = "legendGreen";
      // }

      // if (this.eBenchVal < 0) {
      //   this.ePosBench = false;
      //   document.getElementById("eBenchID").className = "legendRed";
      // } else {
      //   this.ePosBench = true;
      //   document.getElementById("eBenchID").className = "legendGreen";
      // }

      this.populateBenchmark();

    })

  }

  benchmarkDate = new Date;
  benchmarkDateStr;

  populateBenchmark() {

    this.benchmarkDate.setDate(1); // going to 1st of the month
    this.benchmarkDate.setHours(-1); // going to last hour before this date even started.

    var dobArr = this.benchmarkDate.toDateString().split(' ');
    this.benchmarkDateStr = dobArr[2] + ' ' + dobArr[1] + ' ' + dobArr[3];

    var dateArr = [];
    var date;

    let months = this.timestampMonthly;
    let etoro = this.gainsMonthly;
    etoro.pop();
    months.pop();

    let max = 0;
    let maxEtoro = 0;

    let min = 0;

    let combined = [];

    for(let i = 0; i<= this.performanceArrFiltered.length - 1; i++){
      if(this.performanceArrFiltered[i] > max){
        max = this.performanceArrFiltered[i];
      }
    }

    max = Math.round(max / 10) * 10;

    for(let i = 0; i<= etoro.length - 1; i++){
      combined.push(etoro[i])
      // console.log(etoro[i])
      if(etoro[i] > maxEtoro){
        maxEtoro = etoro[i]
      }
    }

    for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
      combined.push(this.NSDQ100ArrFiltered[i]);
    }

    // console.log(combined)

    const negativeInts = combined.filter(i => i < 0)

    min = Math.min(...negativeInts)

    min = min * -1

    min = Math.ceil(min / 5) * 5;

    min = -Math.abs(min)
    // console.log(min)


    for (var i = 0; i <= months.length; i++) {
      // console.log(true)
        
        // if(i % 4 == 0){
        //   console.log(true)
          // this.timestampMonthly[i] == "";
          date = new Date(months[i]);
          dateArr.push(this.convertDate(date))
        // }else{
        //   dateArr.push("")

        // }

      
    }

    let firstDate = dateArr.shift();

    let start = 0;
    let end = dateArr.length - 2;
    let mid = Math.round((start+end)/2);

    let initialise = etoro[0];

    let plot = [];
    initialise = (initialise * 12 / 1);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= etoro.length - 1; i++) {
      if (i == 1) {
        buff.push(etoro[i] + etoro[i - 1]);
      } else {
        buff.push(etoro[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = etoro[0];

    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    this.tGain = buff[7];
    let x = this.tGain.toPrecision(3);
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(2);
    this.tLaunch = z;

    if (this.tGain < 0) {
      document.getElementById("tGain").className = "legendRed";
      this.gPos = false;
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }


    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    let avg = sum / buff.length - 1;

    let agr = sum * 12 / 8;

    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      splineWidth = '3';
      gridOrNot = 0;
    } else {
      splineWidth = '3';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    }



    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#01B150',
              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }
          }
        },

        type: 'column',

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: true, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      yAxis: [{ // Primary yAxis

        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true,

      }, { // Secondary yAxis
        visible: false,
        // title: {
        //   text: 'Monthly Return - (Target > 1.25%)',
        //   style: {
        //     color: Highcharts.getOptions().colors[2],
        //   }
        // },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        tickPositioner: function (min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);

          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max + step)); //hidden - added for top padding

          return ticks;
        },
        gridLineWidth: gridOrNot,
        linkedTo: 2,
        max: maxEtoro,
      }, {
        title: {
          text: ''
        },
        gridLineWidth: 1,
        max: max,
        min: min,
        startOnTick: false,
        endOnTick: false,
        labels: {
          format: '{value}%',
          style: {
            color: "black"
          }
        },
        opposite: false,
      }],

      title: {
        text: '',
      },
      plotOptions: {
        spline: {
          // shared options for all line series
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              lineWidth: splineWidth,
            }
          }
        }
      },
      series: [{
        name: 'eToro',
        data: etoro,
        yAxis: 1,
        color: "#FEDF7E"
      }, {
        yAxis: 2,
        type: 'spline',
        dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Performance +/-',
        color: "#01B150",
        data: this.performanceArrFiltered,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,
        }
      },{
        name: 'NSDQ100',
        data: this.NSDQ100ArrFiltered,
        yAxis: 1,
        color: "#7ED7F6"
      }]

    }
    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 3;
    }
    // Highcharts.chart('benchmarkContainer', this.options);
  }

  gainArr;
  gainArrFiltered = [];
  closedArr;
  closedArrFiltered = [];
  datesArr;
  datesArrFiltered = [];

  async getExecutions() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/executions-values', { headers }).subscribe(data => {
      // console.log(data)
      this.closedArr = data[0].Closed;
      this.gainArr = data[0].GainLoss;
      this.datesArr = data[0].Dates;

      // console.log(this.NSDQ100Arr)
      // console.log(this.performanceArr)

      this.gainArrFiltered = this.gainArr.split(",");
      this.closedArrFiltered = this.closedArr.split(",");
      this.datesArrFiltered = this.datesArr.split(",");
      
      for(let i = 0; i <= this.gainArrFiltered.length - 1; i++){
        this.gainArrFiltered[i] = Number(this.gainArrFiltered[i])
      }

      for(let i = 0; i<= this.closedArrFiltered.length - 1; i++){
        this.closedArrFiltered[i] = Number(this.closedArrFiltered[i]);
      }


      let day;
      let month;
      let year;

      for(let i = 0; i<= this.datesArrFiltered.length - 1; i++){
        day = this.datesArrFiltered[i].substring(0,2);
        month = this.datesArrFiltered[i].substring(3,5);
        year = this.datesArrFiltered[i].substring(6,10);

        this.datesArrFiltered[i] = year + "/" + month + "/" + day;
      }

      // console.log(this.gainArrFiltered)
      // console.log(this.closedArrFiltered)
      

      this.populateExe();

    })

  }

  lastDate;

  populateExe() {
    var dateArr = [];
    var date;

    let maxGains = 0;
    let maxClosed = 0;

    // for(let i = 0; i <= this.gainArrFiltered.length - 1; i++){
    //   if(this.gainArrFiltered[i] > maxGains){
    //     maxGains = this.gainArrFiltered[i];
    //   }
    // }

    for(let i = 0; i <= this.closedArrFiltered.length - 1; i++){
      if(this.closedArrFiltered[i] > maxClosed){
        maxClosed = this.closedArrFiltered[i];
      }
    }

    // maxClosed = Math.round(maxClosed / 10) * 10;
    maxClosed = maxClosed + 1;
    // maxGains = maxGains + 2;

    // console.log(this.datesArrFiltered)

    for (var i = 0; i <= this.datesArrFiltered.length - 1; i++) {
      // console.log(true)
        
        // if(i % 6 == 0){
        //   console.log(true)
          // this.timestampMonthly[i] == "";
          // date = new Date(this.timestampMonthly[i]);
          date = new Date(this.datesArrFiltered[i]);
          // console.log(date)
          dateArr.push(this.convertDate(date))
        // }else{
        //   // dateArr.push(undefined)

        // }

      
    }

    this.lastDate = dateArr[dateArr.length - 1];
    
    let month = this.lastDate.substring(0,3);
    let year = this.lastDate.substring(3,7);
    if(month == '02/'){
      this.lastDate = month + "29/" + year;  
    }else if(month == '04/' || month == '06/' || month == '09/' || month == '11/'){
    this.lastDate = month + "30/" + year;
    }else{
      this.lastDate = month + "31/" + year;
    }
    let theDate = new Date(this.lastDate);
    this.lastDate = theDate.toLocaleString('default', { month: 'long' }) + " " + theDate.getFullYear();

    // let first = this.gainsMonthly.shift();
    let initialise = this.gainsMonthly[0];

    let plot = [];
    initialise = (initialise * 12 / 1);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= this.gainsMonthly.length - 1; i++) {
      if (i == 1) {
        buff.push(this.gainsMonthly[i] + this.gainsMonthly[i - 1]);
      } else {
        buff.push(this.gainsMonthly[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = this.gainsMonthly[0];

    // console.log(buff);
    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    this.tGain = buff[7];
    let x = this.tGain.toPrecision(3);
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(2);
    this.tLaunch = z;

    if (this.tGain < 0) {
      document.getElementById("tGain").className = "legendRed";
      this.gPos = false;
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }


    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    let avg = sum / buff.length - 1;

    let agr = sum * 12 / 8;

    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      splineWidth = '3';
      gridOrNot = 0;
    } else {
      splineWidth = '3';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    }

    this.gainArrFiltered.splice(0, 191);
    this.closedArrFiltered.splice(0,191);
    dateArr.splice(0,191);

    for(let i = 0; i<= this.gainArrFiltered.length - 1; i++){
      this.gainArrFiltered[i] = this.gainArrFiltered[i] * 100.0;
      this.gainArrFiltered[i] = Number(this.gainArrFiltered[i].toFixed(2))
      if(this.gainArrFiltered[i] > maxGains){
        maxGains = this.gainArrFiltered[i];
      }
    }

    maxGains = maxGains + 2;

    let start = 0;
    let end = dateArr.length - 1;
    let mid = Math.round((start+end)/2);

    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            // pointDistance = Math.sqrt(
            //   (point0.plotX - point1.plotX) * (point0.plotX - point1.plotX) +
            //   (point0.plotY - point1.plotY) * (point0.plotY - point1.plotY)
            // );

            // angle = 90 + Math.atan2((point0.plotY - point1.plotY), (point0.plotX - point1.plotX)) * 180 / Math.PI;

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            // console.log(angle);

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            // console.log(calculatedPath)
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#FEC71C',
                // 'stroke-width': 2,

              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }
          }
        },

        // zoomType: 'x',
        type: 'column'

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: true, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true,

      }, { // Secondary yAxis

        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2],
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[0]
          },
        },
        tickPositioner: function (min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);

          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max + step)); //hidden - added for top padding

          return ticks;
        },
        max: maxClosed,
        startOnTick: false,
        endOnTick: false,
        plotLines: [{
          value: 0,
          width: 1,
          color: '#aaa',
          zIndex: 10
        }],
        lineColor: Highcharts.getOptions().colors[0],
        lineWidth: 4,
        gridLineWidth: gridOrNot
      }, {
        gridLineWidth: gridOrNot,
        // linkedTo:1,
        max: maxGains,
        startOnTick: false,
        endOnTick: false,
        tickInterval: 15,
        title: {
          text: '',
          style: {
            color: "#FEC71C"
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: "#FEC71C"
          },
        },
        opposite: true,
        lineColor: "#FEC71C",
        lineWidth: 4,
        
      }],

      title: {
        text: '',
      },
      plotOptions: {
        column: {
          maxPointWidth: 100,
          zones: [{
            value: 0, // Values up to 10 (not including) ...
            color: 'red' // ... have the color blue.
          }, {
            color: '#6AAC0E' // Values from 10 (including) and up have the color red
          }]
        },
        line: {
          // shared options for all line series
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              lineWidth: splineWidth,
            }
          }
        }
      },
      series: [{
        name: 'Closed Trades',
        data: this.closedArrFiltered,
        yAxis: 1,
        color: "#B0D2F4"
      }, {
        yAxis: 2,
        type: 'line',
        // dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Gain / Loss Rate',
        color: '#FEC71C',
        data: this.gainArrFiltered,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,

          //   symbol: 'triangle', 
          // fillColor: 'rgba(126,86,100,.9)', /* match to the color of your column */
          // radius:25
        }
      }]

    }
    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 100;
    }
    Highcharts.chart('exeContainer', this.options);
  }

  unrealisedGPos: boolean = false;
  netAssetPos: boolean = false;

  unrealisedG;
  netAsset;

  async getExecutionBanner() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/executions-dialog', { headers }).subscribe(data => {
      data.forEach(number => {
        // console.log(number);

        this.unrealisedG = number.UnrealisedGain;
        this.netAsset = number.NetAssetValue;
        
       
        if (this.unrealisedG < 0) {
          this.unrealisedGPos = false;
          document.getElementById("unrealised").className = "legendRed";
        } else {
          this.unrealisedGPos = true;
          document.getElementById("unrealised").className = "legendGreen";
        }

        if(this.unrealisedG == 0){
          this.unrealisedG = "0.00";
        }

        if (this.netAsset < 0) {
          this.netAssetPos = false;
          document.getElementById("netasset").className = "legendRed";
        } else {
          this.netAssetPos = true;
          document.getElementById("netasset").className = "legendGreen";
        }

        if(this.netAsset == 0){
          this.netAsset = "0.00";
        }

      })
    })


  }

  close(video){
    // document.getElementById(video);
    // console.log(video)
    video.pause();
  }

  capturePos(){
    // console.log(window.scrollY, "scrollY")
    // console.log(window.screenY, "screenY")
    sessionStorage.setItem("windowPos", window.scrollY.toString())
  }

  @HostListener("window:beforeunload", ["$event"])
  refreshDetected(){
    // console.log("refreshed!");
    sessionStorage.setItem("windowPos", window.scrollY.toString());
  }
}

